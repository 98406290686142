
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




























































































































































































.advantage {
  overflow: hidden;
  width: calc(100% - 3rem);
  height: 3.7rem;
  padding: 0.5rem;
  background: $white;
  border-radius: 1.6rem;
}

.advantage__item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.3rem;
  line-height: 1;
  background: transparent;
  border: 0;
  border-radius: 1.6rem;

  .icon {
    display: block;
    fill: $white;
    background-color: $c-blue-medium;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 5rem;
    margin-right: 1rem;
    margin-left: auto;
    transform: rotate(-90deg);
    transition: transform 0.12s;
  }

  .is-solo & {
    .icon {
      display: none;
    }
  }

  .is-open & {
    .icon {
      transform: scale(-1);
    }
  }

  &:hover {
    .is-solo & {
      cursor: initial;
    }

    .is-open & {
      color: $white;
      background-color: $c-bunting;

      .icon {
        fill: $white;
        transform: scale(-1);
      }
    }

    .pack-choice & {
      cursor: initial;

      .is-open & {
        color: $c-gray-darkest;
        background-color: transparent;
      }
    }
  }
}

.advantage__item__label {
  width: 8rem;
  margin-right: 1rem;
  white-space: nowrap;
}

.advantage__item__content {
  display: flex;
  align-items: center;

  ::v-deep .tooltip {
    margin-left: 1rem;
  }

  .is-solo & {
    width: 100%;

    ::v-deep .tooltip {
      margin-left: auto;
    }
  }
}

.advantage__item__tooltip {
  font-size: 1.3rem;

  ::v-deep p {
    margin: 1rem 0;
  }
}
